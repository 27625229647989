import * as Sentry from "@sentry/browser";
import { isProd } from "./env";

export function buildApiUrl(path: string) {
  let url = "";

  try {
    const clientDB = getClientDB() || "dev01";

    // build the url with the origin and the first item
    url = `/${clientDB}/${path}`;

    return url;
  } catch (error) {
    // send out error to error logging service
    Sentry.captureException(error);

    return "";
  }
}

export function getClientDB(pathname = window.location.pathname) {
  try {
    // split the url into an array
    const urlArray = pathname.split("/").filter((item) => item !== "");

    // retrieve the first item in the array (clientDB)
    let clientDB = urlArray[0] || "";
    if (clientDB === "iframe.html") {
      clientDB = urlArray[1];
    }

    // if clientDB is undefined and not in development env, send out error to error logging service
    if (!clientDB) {
      if (!isProd()) {
        clientDB = "dev01";
      } else {
        Sentry.captureMessage("Client DB is undefined");
      }
    }

    return clientDB;
  } catch (error) {
    // send out error to error logging service
    Sentry.captureException(error);
  }
}

export function getCleanPath(path: string) {
  return path.replace(/\/\//g, "/");
}

interface IRedirectToEntryOptions {
  originOnly?: boolean;
}

export function redirectToEntry(
  startingPath: string,
  { originOnly = false }: IRedirectToEntryOptions = {}
) {
  const clientDB = getClientDB();
  const currentPath = window.location.pathname;
  const cleanCurrentPath = getCleanPath(currentPath);

  // if originOnly is true, redirect from the origin to starting path
  if (originOnly) {
    if (
      cleanCurrentPath === "/" ||
      cleanCurrentPath === `/${clientDB}` ||
      cleanCurrentPath === `/${clientDB}/`
    ) {
      // redirect to starting path
      window.location.pathname = `/${clientDB}/${startingPath}`;
      return;
    }

    // do nothing
    return;
  }

  const entryPath = `/${clientDB}/${startingPath}`;
  const isEntryPath = cleanCurrentPath === getCleanPath(entryPath);

  // do not redirect if already on the entry path
  if (isEntryPath) return;

  // redirect from / to /dev01/common/login
  const isEntry = cleanCurrentPath === "/";

  // redirect from /<clientDB>/ to /<clientDB>/common/login
  const isClientPath = /^\/\w+\/?/g.test(currentPath);

  let nextPath = "";

  if (isEntry) {
    nextPath = `/dev01/${startingPath}`;
  } else if (isClientPath && !isEntryPath) {
    nextPath = `/${clientDB}/${startingPath}`;
  }

  // replace all // with /
  nextPath = nextPath.replace(/\/\//g, "/");

  // redirect to the next path
  window.location.pathname = nextPath;
}
