import { UserInfoDTO } from "@react-ms-apps/common/types/user";
import { getClientDB } from "@react-ms-apps/common/utils";
import axios from "axios";

export async function fetchUserAuthenticated(): Promise<boolean> {
  const authed = await axios
    .get(`/${getClientDB()}/api/nc/whoami`)
    .then((response) => {
      if (response.status === 200) {
        return true;
      }
    })
    .catch(() => {
      return false;
    });

  return !!authed;
}

export async function fetchUserInfo(): Promise<UserInfoDTO> {
  const resp = await axios.get<UserInfoDTO>(`/${getClientDB()}/api/nc/whoami`);
  return resp.data;
}
