import { UploadProcessTypes } from "@react-ms-apps/common/constants/uploadProcessTypes";

export const LegacyUtilities = {
  [UploadProcessTypes.StatementEditor]: "/Utility/Refresh/batchStatementEditor",
  [UploadProcessTypes.AssetRefresh]: "/Utility/Refresh/assetRefresh",
  [UploadProcessTypes.UserCreation]: "/Utility/Refresh/userCreation",
  [UploadProcessTypes.DeviceCostCenterRefresh]: "/Utility/Refresh/ccRefresh",
  [UploadProcessTypes.EmployeeCostCenterRefresh]: "/Utility/Refresh/ccRefresh",
  [UploadProcessTypes.MakeManager]: "/Utility/Refresh/makeManager",
  [UploadProcessTypes.ManagerDevice]: "/Utility/Refresh/deviceManager",
  [UploadProcessTypes.UpdateEmployeeManager]:
    "/Utility/Refresh/employeeManager",
  [UploadProcessTypes.PlanOverride]: "/Utility/PlanOverride",
  UtilityList: "/Utility",
  UtilityManagement: "/Utility/UtilityManagement",
  OrderTypesManagement: "/Utility/OrderTypes/orderTypes",
  StatementSummaryEditor: "/Utility/StatementSummaryEditor",
};

const ROUTES = {
  BASE: "/",
  LOGIN: {
    HOME: "/login",
    FORGOT_PASSWORD: "/forgotPassword",
    SENT_PASSWORD_RESET: "/sentPasswordReset",
    PASSWORD_RESET: "/passwordReset",
  },
  LOGOUT: "/logout",
  Utility: {
    UTILITIES_LIST: "/utilities-list",
    UPLOAD_PROCESSOR: "/upload-processor",
    UTILITIES_EDITOR: "/utilities-editor",
    ORDER_TYPES_EDITOR: "/order-types-editor",
    STATEMENT_SUMMARY_EDITOR: "/statement-editor",
    BATCH_STATEMENT_EDITOR: LegacyUtilities[UploadProcessTypes.StatementEditor],
    BATCH_ASSET_REFRESH: LegacyUtilities[UploadProcessTypes.AssetRefresh],
    BATCH_USER_CREATION: LegacyUtilities[UploadProcessTypes.UserCreation],
    BATCH_DEVICE_COST_CENTER_REFRESH:
      LegacyUtilities[UploadProcessTypes.DeviceCostCenterRefresh],
    BATCH_EMPLOYEE_COST_CENTER_REFRESH:
      LegacyUtilities[UploadProcessTypes.EmployeeCostCenterRefresh],
    BATCH_MAKE_MANAGER: LegacyUtilities[UploadProcessTypes.MakeManager],
    BATCH_MANAGER_DEVICE_REFRESH:
      LegacyUtilities[UploadProcessTypes.ManagerDevice],
    BATCH_UPDATE_EMPLOYEE_MANAGER:
      LegacyUtilities[UploadProcessTypes.UpdateEmployeeManager],
    BATCH_PLAN_OVERRIDE: LegacyUtilities[UploadProcessTypes.PlanOverride],
    LEGACY_UTILITY_MANAGEMENT: LegacyUtilities.UtilityManagement,
    LEGACY_UTILITY_LIST: LegacyUtilities.UtilityList,
    LEGACY_ORDER_TYPES_MANAGEMENT: LegacyUtilities.OrderTypesManagement,
    LEGACY_STATEMENT_SUMMARY_EDITOR: LegacyUtilities.StatementSummaryEditor,
  },
};

export default ROUTES;
