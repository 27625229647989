import { PartnerOrg } from "@react-ms-apps/common/types/org";
import { buildApiUrl } from "@react-ms-apps/common/utils";
import axios from "axios";

export async function fetchCopyrightRemoved(): Promise<boolean> {
  const resp = await axios.get(buildApiUrl("api/nc/option_values"), {
    params: {
      option_name: "remove_copyright",
    },
  });

  return resp.data.option_value;
}

export async function fetchForgotPasswordRemoved(): Promise<boolean> {
  const resp = await axios.get(buildApiUrl("api/nc/option_values"), {
    params: {
      option_name: "disable_forgot_password",
    },
  });

  return resp.data.option_value;
}

export async function fetchSuppressMobilSenseLogo(): Promise<boolean> {
  const resp = await axios.get(buildApiUrl("api/nc/option_values"), {
    params: {
      option_name: "suppress_mobilsense_logo",
    },
  });

  return resp.data.option_value;
}

export async function fetchVendorLogo(): Promise<string | null> {
  const resp = await axios.get(buildApiUrl("api/nc/option_values"), {
    params: {
      option_name: "vendor_logo",
    },
  });

  return resp.data.option_value || null;
}

export async function fetchOrgInfo(): Promise<PartnerOrg> {
  const resp = await axios.get(buildApiUrl("api/restables/organizations"));

  return resp.data[0];
}

export async function fetchCustomLoginMessage(): Promise<string | null> {
  const resp = await axios.get(buildApiUrl("api/nc/option_values"), {
    params: {
      option_name: "login_custom_html",
    },
  });

  return resp.data.option_value;
}
