import { isDev, redirectToEntry } from "@react-ms-apps/common";
import "@react-ms-apps/common/config/sentry";
import "@react-ms-apps/common/styles/base.scss";
import { setAppVersion } from "@react-ms-apps/common/utils/app-version";
import Logout, { isLogoutRoute } from "@react-ms-apps/common/utils/logout";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import pjson from "../package.json";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

(function initApp() {
  if (isLogoutRoute(window.location)) {
    Logout();
    return;
  }

  const version = pjson.version;
  setAppVersion("login", version);

  // add favicon to document
  const favicon = document.createElement("link");
  favicon.rel = "icon";
  favicon.href = "/favicon.ico";

  // add title to document
  document.title = "MobilSentry";

  if (isDev()) {
    // set page title to show environment
    document.title = `MobilSentry - ${process.env.NODE_ENV}`;

    // redirects to entry page if not in production
    redirectToEntry("login", { originOnly: true });
  }

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <Sentry.ErrorBoundary>
        <App />
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  );

  // log app version
  console.log(`App version: ${version}`);

  // log environment
  console.log(`Environment: ${process.env.NODE_ENV}`);

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
