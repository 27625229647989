import { buildApiUrl, isDev } from "@react-ms-apps/common/utils";
import { goToPage } from "@react-ms-apps/common/utils/url";
import axios from "axios";

type AuthenticationMode = "legacy" | "transitional" | "pyramid";

interface FetchAuthenticationModeResponse {
  option_value: {
    mode: AuthenticationMode;
  };
}

export async function fetchAuthenticationMode(): Promise<AuthenticationMode> {
  const resp = await axios.get<FetchAuthenticationModeResponse>(
    `${buildApiUrl("api/nc/option_values")}`,
    {
      params: {
        option_name: "authentication_settings",
      },
    }
  );

  return resp.data.option_value.mode;
}

export async function establishZopeSession() {
  await axios.get(buildApiUrl(""));
}

export async function legacyLogin(user_name: string, password: string) {
  const callLogin = async () => {
    const headers = {
      Accept:
        "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7",
      // "Accept-Language": "en-US,en;q=0.9",
      // "Cache-Control": "no-cache",
      // Connection: "keep-alive",
      // "Content-Type": "application/x-www-form-urlencoded",
      // Pragma: "no-cache",
      // "Sec-Fetch-Dest": "document",
      // "Sec-Fetch-Mode": "navigate",
      // "Sec-Fetch-Site": "same-origin",
      // "Sec-Fetch-User": "?1",
      // "Upgrade-Insecure-Requests": "1",
    };

    const data = new URLSearchParams();
    data.append("user_name", user_name);
    data.append("password", password);
    data.append("submit", "");

    const resp = await axios.post(buildApiUrl("common/login"), data, {
      headers,
    });

    if (resp.data.includes("Invalid username or password")) {
      throw new Error("Invalid username or password");
    }

    return true;
  };

  const callBaseApi = async () => {
    const headers = {
      Accept:
        "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7",
      // "Accept-Language": "en-US,en;q=0.9",
      "Cache-Control": "no-cache",
      // Connection: "keep-alive",
      // Pragma: "no-cache",
      // "Sec-Fetch-Dest": "document",
      // "Sec-Fetch-Mode": "navigate",
      // "Sec-Fetch-Site": "same-origin",
      // "Sec-Fetch-User": "?1",
      // "Upgrade-Insecure-Requests": "1",
    };

    await axios.get(buildApiUrl("api"), { headers });
  };

  await callLogin();
  await callBaseApi();

  return true;
}

export async function pyramidLogin(user_name: string, password: string) {
  const resp = await axios.post(`${buildApiUrl("api/login")}`, {
    user_name,
    password,
  });

  return resp.status === 204;
}

export async function fetchLandingPage(): Promise<string> {
  const resp = await axios.get(`${buildApiUrl("api/landing_page")}`);

  return resp.data;
}

export async function authenticate(user_name: string, password: string) {
  try {
    // discover which login method to use
    const authenticationMode = await fetchAuthenticationMode();
    const auths: boolean[] = [];

    // call legacy login
    if (
      authenticationMode === "legacy" ||
      authenticationMode === "transitional"
    ) {
      const legacySuccess = await legacyLogin(user_name, password);
      auths.push(legacySuccess);
    }

    // call new login
    if (
      authenticationMode === "pyramid" ||
      authenticationMode === "transitional"
    ) {
      const pyramidSuccess = await pyramidLogin(user_name, password);
      auths.push(pyramidSuccess);
    }

    // check if any auths were successful
    const authSuccess = auths.some((auth) => auth === true);

    if (!authSuccess) {
      throw new Error("Invalid username or password");
    }
  } catch (err) {
    throw err;
  }
}

export async function login(user_name: string, password: string) {
  try {
    await authenticate(user_name, password);

    // fetch landing page
    const landingPage = await fetchLandingPage();

    let origin = window.location.origin;
    if (isDev()) {
      origin = "https://dev.mobilsense.com";
    }
    const href = `${origin}${landingPage}`;
    goToPage(href);
  } catch (err) {
    throw err;
  }
}

export async function logout() {
  try {
    await axios.get(`${buildApiUrl("api/logout")}`);
  } catch (err) {
    throw err;
  }
}
