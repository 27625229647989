import LockResetIcon from "@mui/icons-material/LockReset";
import { LoadingButton } from "@mui/lab";
import { Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { ROUTES, login } from "@react-ms-apps/common";
import { resetPassword } from "@react-ms-apps/common/api/password";
import ConfirmPassword from "@react-ms-apps/common/components/Password/ConfirmPassword";
import NewPassword from "@react-ms-apps/common/components/Password/NewPassword";
import * as Sentry from "@sentry/react";
import { useCallback, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BACK_TO_LOGIN_TEXT,
  RESEND_RESET_EMAIL_TEXT,
  RESET_PASSWORD_BUTTON_TEXT,
  RESET_PASSWORD_HEADER_TEXT,
} from "../../constants/text";
import LoginWrapper from "../LoginWrapper";

export default function ResetPassword() {
  const { search } = useLocation();

  const token = new URLSearchParams(search).get("token");

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordValid, setNewPasswordValid] = useState(false);
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleResetPassword = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (!newPasswordValid || !confirmPasswordValid || !token) return;

      setIsSaving(true);

      try {
        // get username
        const user_name = await resetPassword(newPassword, token);
        if (user_name) {
          // attempt to login and redirect to landing page for user
          await login(user_name, newPassword);
        }
      } catch (error) {
        toast.error(
          "There was an error resetting your password. Try again, or try getting a new reset email."
        );
        Sentry.captureException(error);
      } finally {
        setIsSaving(false);
      }
    },
    [confirmPasswordValid, newPassword, newPasswordValid, token]
  );

  return (
    <LoginWrapper>
      <Avatar className="m-1 mb-2" sx={{ bgcolor: "secondary.main" }}>
        <LockResetIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {RESET_PASSWORD_HEADER_TEXT}
      </Typography>
      <Box
        component="form"
        role="form"
        onSubmit={handleResetPassword}
        noValidate
        className="w-full mt-1"
      >
        <NewPassword
          onChange={(e) => setNewPassword(e.target.value)}
          onValidityChange={setNewPasswordValid}
        />
        <ConfirmPassword
          password={newPassword}
          onValidityChange={setConfirmPasswordValid}
        />
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          className="!mt-6 !mb-4"
          loading={isSaving}
        >
          {RESET_PASSWORD_BUTTON_TEXT}
        </LoadingButton>
        <Divider className="!border-dashed !mb-4 !mt-2" />
        <div className="flex justify-between">
          <Link component={RouterLink} to={ROUTES.LOGIN.HOME}>
            {BACK_TO_LOGIN_TEXT}
          </Link>
          <Link component={RouterLink} to={ROUTES.LOGIN.FORGOT_PASSWORD}>
            {RESEND_RESET_EMAIL_TEXT}
          </Link>
        </div>
      </Box>
    </LoginWrapper>
  );
}
