import { ThemeProvider } from "@mui/material";
import theme from "@react-ms-apps/common/mui/theme";
import { NavData } from "@react-ms-apps/common/types/nav";
import { useState } from "react";
import ChangePasswordDialog from "./ChangePasswordDialog";
import NavItem from "./NavItem";
import { NavbarWrapper } from "./styled";

interface NavBarProps {
  navData: NavData.NavDataItem[];
  useAsComponent: boolean;
}

const NavBar = ({ navData, useAsComponent }: NavBarProps) => {
  const [showChangePasswordDialog, setShowChangePasswordDialog] =
    useState(false);

  const handleListItemClick = (item: NavData.NavDataItem) => {
    if (item.link_name !== "Password") return;

    setShowChangePasswordDialog(true);
  };

  if (!navData) return null;

  return (
    <ThemeProvider theme={theme}>
      <NavbarWrapper>
        {navData.map((item: NavData.NavDataItem, index) => (
          <NavItem
            index={index}
            key={item.id}
            item={item}
            onClick={handleListItemClick}
          />
        ))}
      </NavbarWrapper>
      <ChangePasswordDialog
        onClose={() => setShowChangePasswordDialog(false)}
        open={showChangePasswordDialog}
      />
    </ThemeProvider>
  );
};

export default NavBar;
