import { CssBaseline, ThemeProvider } from "@mui/material";
import { fetchForgotPasswordRemoved } from "@react-ms-apps/common/api/assets";
import { Footer, Header } from "@react-ms-apps/common/components";
import ROUTES from "@react-ms-apps/common/constants/routes";
import theme from "@react-ms-apps/common/mui/theme";
import { getClientDB } from "@react-ms-apps/common/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./Components/ForgotPassword";
import Login from "./Components/Login";
import ResetPassword from "./Components/ResetPassword";
import SentPasswordReset from "./Components/SentPasswordReset";

enum AssetsLoadedStates {
  Initial = "initial",
  Loading = "loading",
  Success = "success",
  Error = "error",
}

type AssetsLoadedState = "initial" | "loading" | "success" | "error";

function App() {
  const [forgotPasswordRemoved, setForgotPasswordRemoved] = useState(false);
  const [assetsLoadedState, setAssetsLoadedState] = useState<AssetsLoadedState>(
    AssetsLoadedStates.Initial
  );

  const client = useMemo(() => {
    return getClientDB();
  }, []);

  // @TODO check if user is already logged in
  // @TODO redirect to landing page if user is already logged in

  // @TODO fetch dynamic assets from API

  const fetchAssets = useCallback(async () => {
    if (assetsLoadedState !== AssetsLoadedStates.Initial) {
      return;
    }

    setAssetsLoadedState(AssetsLoadedStates.Loading);

    try {
      //  forgot password removed
      const forgotPasswordRemoved = await fetchForgotPasswordRemoved();
      setForgotPasswordRemoved(forgotPasswordRemoved);

      setAssetsLoadedState(AssetsLoadedStates.Success);
    } catch (error) {
      setAssetsLoadedState(AssetsLoadedStates.Error);
    }
  }, [assetsLoadedState]);

  useEffect(() => {
    fetchAssets();
  }, [fetchAssets]);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter basename={`${client}`}>
        <CssBaseline />

        <div className="h-full flex flex-col">
          <Header showNavigation={false} />

          <Routes>
            <Route
              path={ROUTES.LOGIN.HOME}
              element={<Login allowForgotPassword={!forgotPasswordRemoved} />}
            />

            {/* Do not allow route if forgotPasswordRemoved is true */}
            {!forgotPasswordRemoved && (
              <>
                <Route
                  path={ROUTES.LOGIN.FORGOT_PASSWORD}
                  element={<ForgotPassword />}
                />

                <Route
                  path={ROUTES.LOGIN.SENT_PASSWORD_RESET}
                  element={<SentPasswordReset />}
                />

                <Route
                  path={`${ROUTES.LOGIN.PASSWORD_RESET}`}
                  element={<ResetPassword />}
                />
              </>
            )}

            <Route
              path="*"
              element={<Navigate to={ROUTES.LOGIN.HOME} replace />}
            />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
    </ThemeProvider>
  );
}

export default App;
