import { Container, Paper } from "@mui/material";
import React from "react";

interface LoginWrapperProps {
  children: React.ReactNode;
  loginMessage?: string;
}

export default function LoginWrapper({
  children,
  loginMessage,
}: LoginWrapperProps) {
  return (
    <Container
      component="main"
      maxWidth="xs"
      className="!flex flex-1 justify-center align-center flex-col -mt-20"
    >
      <Paper
        className="rounded-xl px-8 py-4"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {children}
      </Paper>

      {!!loginMessage && (
        <div
          data-testid="login-message"
          className="mt-4"
          dangerouslySetInnerHTML={{ __html: loginMessage }}
        />
      )}
    </Container>
  );
}
