// collation of all upload process types
// any "Low" priority items are not included
// http://alpine.mobilsense.com:8180/browse/MOB-3159

export enum UploadProcessTypes {
  StatementEditor = "StatementEditor",
  AssetRefresh = "AssetRefresh",
  UserCreation = "UserCreation",
  DeviceCostCenterRefresh = "DeviceCostCenterRefresh",
  EmployeeCostCenterRefresh = "EmployeeCostCenterRefresh",
  // BatchUpdateLinkUsers = "BatchUpdateLinkUsers",
  MakeManager = "MakeManager",
  ManagerDevice = "ManagerDevice",
  UpdateEmployeeManager = "UpdateEmployeeManager",
  PlanOverride = "PlanOverride",
  // OrderSubscriberRecords = "OrderSubscriberRecords",
  // OrderSubsribersUpdate = "OrderSubsribersUpdate",
  Unknown = "Unknown",
}

export const UploadProcessTypeNames: {
  [key in UploadProcessTypes]: string;
} = {
  [UploadProcessTypes.StatementEditor]: "Batch Update: Statement Editor",
  [UploadProcessTypes.AssetRefresh]: "Batch Update: Asset(s) Refresh",
  [UploadProcessTypes.UserCreation]: "Batch Update: User Creation",
  [UploadProcessTypes.DeviceCostCenterRefresh]:
    "Batch Update: Subscriber Name and Cost Centers",
  [UploadProcessTypes.EmployeeCostCenterRefresh]:
    "Batch Update: Subscriber Information and Cost Centers",
  // BatchUpdateLinkUsers = "Batch Update: Link Users",
  [UploadProcessTypes.MakeManager]: "Batch Update: Make Managers",
  [UploadProcessTypes.ManagerDevice]: "Batch Update: Update Manager Device",
  [UploadProcessTypes.UpdateEmployeeManager]:
    "Batch Update: Update Employees' Manager",
  [UploadProcessTypes.PlanOverride]: "Plan Override Utility",
  // OrderSubscriberRecords = "Batch Update: Create Subscriber Records",
  // OrderSubsribersUpdate = "Batch Update: Update Orders / Subscribers",
  [UploadProcessTypes.Unknown]: "",
};

export interface UploadProcessUIField {
  label: string;
  required: boolean;
  notes?: string;
}

export interface UploadProcessUI {
  processType: UploadProcessTypes;
  title: string;
  description: string[];
  fields: UploadProcessUIField[];
  uploadFormat: string;
  allowBlankValuesFields?: boolean;
}
