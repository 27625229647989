import * as Sentry from "@sentry/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { fetchCopyrightRemoved } from "../../api/assets";

export function Footer() {
  const [copyrightRemoved, setCopyrightRemoved] = useState(true);

  const fetchCopyRightRemoved = async () => {
    try {
      const copyRightRemoved = await fetchCopyrightRemoved();
      setCopyrightRemoved(copyRightRemoved);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    fetchCopyRightRemoved();
  }, []);

  return (
    <div
      data-testid="copyright-wrapper"
      className="fixed bottom-0 left-0 right-0 flex bg-primary-900 text-white text-right p-2 justify-end text-xs z-50 min-h-[32px]"
    >
      {copyrightRemoved
        ? ""
        : `Copyright ©2001 - ${moment().year()} MobilSense, Inc. All rights reserved.`}
    </div>
  );
}
