type NullableNumber = number | null;
type NullableString = string | null;

export namespace BanSummary {
  export type BanSummaries = BanSummary[];

  export type EditableField =
    | "equipment_charges"
    | "equipment_tax"
    | "total_tax"
    | "activation_charge"
    | "termination_charge"
    | "kb_charges"
    | "intl_data_roam_charges"
    | "monthly_access"
    | "total_other"
    | "download_charges"
    | "net_feature_charges"
    | "pda_charges";

  // export enum StatementSummaryField {
  //   EquipmentCharges = "equipment_charges",
  //   EquipmentTax = "equipment_tax",
  //   TotalTax = "total_tax",
  //   ActivationCharge = "activation_charge",
  //   TerminationCharge = "termination_charge",
  //   DataCharges = "kb_charges",
  //   DataRoamingCharges = "intl_data_roam_charges",
  //   MonthlyAccess = "monthly_access",
  //   TotalOther = "total_other",
  //   DownloadCharges = "download_charges",
  //   NetFeatureCharges = "net_feature_charges",
  //   PDACharges = "pda_charges"
  // }

  export interface Carrier {
    contract_discount: number;
    name: string;
    min_pooling: number;
    country_id: number;
    max_pooling: number;
    expedite_charge: number;
    waive_termination: boolean;
    carrier_id: number;
    carrier_logo: string;
    data_discount: number;
    active: boolean;
    id: number;
    monthly_termination_fee?: any;
    waive_activation: boolean;
  }

  export interface BanSummary {
    is_approved?: any;
    equipment_tax: number;
    equipment_tax_delta?: number;
    sms_intl_charges: number;
    termination_charge: number;
    termination_charge_delta?: number;
    mms_charges: number;
    updated_at: string;
    peer_to_peer_charges: number;
    web_charges?: any;
    adjusted_total_tax: NullableNumber;
    download_charges: number;
    download_charges_delta?: number;
    pda_charges: number;
    pda_charges_delta?: number;
    net_feature_charges: number;
    net_feature_charges_delta?: number;
    airtime_charges: number;
    foundation_account: string;
    call_forwarding_charges?: any;
    roam_charges: number;
    monthly_access: number;
    monthly_access_delta?: number;
    ld_charges: number;
    activation_charge: number;
    activation_charge_delta?: number;
    intl_data_roam_charges: number;
    intl_data_roam_charges_delta?: number;
    local_tax: number;
    kb_charges: number;
    kb_charges_delta?: number;
    start_date?: any;
    adjusted_equipment_charges: NullableNumber;
    total_tax: number;
    total_tax_delta?: number;
    equipment_charges: number;
    equipment_charges_delta?: number;
    end_date?: any;
    intl_roam_charges: number;
    invoice_date: string;
    account_statement_id: number;
    sms_charges: number;
    retail_monthly_access: number;
    approval_timestamp?: any;
    invoice_number: string;
    data_roam_charges?: any;
    kb_pool_usage?: any;
    adjusted_total_charges: NullableNumber;
    approval_comments: string;
    total_charges: number;
    total_other: number;
    total_other_delta?: number;
    carrier_subaccount_number: string;
    statement_summary_id: number;
    statement_summary: StatementSummary;
    federal_tax: number;
    intl_ld_charges: number;
    approver_id?: any;
    created_at: string;
    contract_plan_discounts: number;
    adjusted_kb_charges?: any;
    carrier_account_number: string;
    state_tax: number;
    county_tax: number;
    adjusted_monthly_access?: any;
    mms_intl_charges?: any;
  }

  export interface StatementSummary {
    created_at: string;
    personal_minutes: number;
    equipment_tax: number;
    voice_delta?: any;
    c3_cost_ctr: string;
    intl_roam_minutes: number;
    pro_total_tax: number;
    pda_charges: number;
    offoffpeak_incoming_minutes: number;
    peer_to_peer_delta?: any;
    activation_charge: number;
    mms_intl_roam_charges: number;
    history_profile_id: number;
    status_time: string;
    pro_peer_to_peer_charges: number;
    pro_monthly_access: number;
    number_of_text_messages: number;
    vac_override_p: number;
    web_charges?: any;
    pro_intl_ld_charges: number;
    federal_tax: number;
    intl_roam_charges: number;
    nw_minutes: number;
    retail_monthly_access: number;
    vac_override_b: number;
    friends_feature_minutes: number;
    m2m_7to9pm: number;
    pro_ld_charges: number;
    p2t_nationwide_minutes?: any;
    pro_download_charges: number;
    business_minutes: number;
    orig_total_b: number;
    emp_override_b: number;
    mobile_to_mobile_minutes: number;
    c6_cost_ctr: string;
    peak_incoming_minutes: number;
    carrier_subaccount_number?: any;
    prorated_total_charges: number;
    trav_override_p: number;
    intl_ld_charges: number;
    global_call_credit?: any;
    p2t_nationwide_charges?: any;
    pro_total_data?: any;
    contract_plan_discounts: number;
    pro_state_tax: number;
    pro_directory_asst: number;
    emp_override_p: number;
    voice_variance_score?: any;
    non_m2m_6to7am: number;
    trav_override_b: number;
    mms_intl_count: number;
    pooling_credit: number;
    m2m_6to7pm: number;
    total_p: number;
    total_minutes_of_use: number;
    manager_name: string;
    email_notification_time?: any;
    company_cost_ctr: string;
    pool_group: string;
    upgrade_charge?: any;
    free_kb?: any;
    division_cost_ctr: string;
    net_feature_charges: number;
    installment_credit_total?: any;
    mms_incoming_count: number;
    offpeak_incoming_minutes: number;
    pro_kb_charges: number;
    excess_minute_adj: number;
    intl_data_roam_usage: number;
    total_b: number;
    monthly_access: number;
    pro_mms_charges: number;
    project_cost_ctr: string;
    batch_id: number;
    kb_charges: number;
    m2m_6to7am: number;
    acct_cost_ctr: string;
    pro_net_feature_charges: number;
    business_limit_adj: number;
    non_m2m_7to9pm: number;
    invoice_period_start: string;
    kb_intl_rated_domestic_usage?: any;
    m2m_offpeak_outgoing_minutes: number;
    pro_airtime_charges: number;
    pro_intl_data_roam_charges: number;
    installment_total_count?: any;
    data_roam_usage?: any;
    task_cost_ctr: string;
    m2m_peak_outgoing_minutes: number;
    installment_current_count?: any;
    intl_ld_minutes: number;
    mms_count: number;
    pooling_summary_id?: any;
    unallocated_charges: number;
    installment_mrc?: any;
    contracted_plan_code: string;
    m2m_peak_incoming_minutes: number;
    service_number: string;
    pro_local_tax: number;
    intl_data_roam_charges: number;
    sms_incoming_charges: number;
    data_pooling_credit: number;
    directory_asst: number;
    non_m2m_6to7pm: number;
    peer_to_peer__variance_score?: any;
    sms_intl_roam_charges: number;
    c4_cost_ctr: string;
    additional_minutes_of_use: number;
    invoice_period_end: string;
    p2t_group_minutes?: any;
    termination_charge: number;
    installment_credit_mrc?: any;
    carrier_id: number;
    pro_call_forwarding_charges?: any;
    pro_county_tax: number;
    domestic_ld_minutes: number;
    airtime_charges: number;
    user_id: number;
    foundation_account: string;
    personal_include_charges: number;
    name: string;
    ld_charges: number;
    pro_equipment_charges: number;
    is_final: boolean;
    manager_id: number;
    sms_intl_charges: number;
    local_tax: number;
    start_date: string;
    p2t_group_charges?: any;
    included_minutes_of_use: number;
    pro_plan_definition_id?: any;
    end_date: string;
    status_id: number;
    reimburse: boolean;
    sms_incoming_count: number;
    business_include_charges: number;
    mms_charges: number;
    m2m_offpeak_incoming_minutes: number;
    invoice_number: string;
    carrier_account_number: string;
    pro_pda_charges: number;
    c5_cost_ctr: string;
    c2_cost_ctr: string;
    c1_cost_ctr: string;
    p2t_intl_minutes?: any;
    peak_other: number;
    state_tax: number;
    sms_m2m_count: number;
    plan_definition_id: number;
    parent_plan_cost: number;
    pct_change_b: number;
    net_change_b: number;
    landline_feature_minutes: number;
    total_other: number;
    domestic_roam_minutes: number;
    updated_at: string;
    pro_federal_tax: number;
    pct_change_p: number;
    pro_equipment_tax: number;
    net_change_p: number;
    installment_balance?: any;
    uplift: number;
    id: number;
    pro_sms_charges: number;
    p2t_intl_charges?: any;
    installment_credit_balance?: any;
    call_forwarding_charges?: any;
    pro_total_voice?: any;
    peer_to_peer_minutes: number;
    sms_intl_roam_count: number;
    roam_charges: number;
    zero_use_workflow_id?: any;
    pro_other: number;
    free_minutes: number;
    department_cost_ctr: string;
    pro_intl_roam_charges: number;
    pool_family_id?: any;
    usage_start_date: string;
    status: number;
    total_tax: number;

    mms_incoming_charges: number;
    equipment_charges: number;

    pb_charges_b: number;
    orig_total_p: number;
    plan_code: string;
    sms_charges: number;
    usage_end_date: string;
    pb_charges_p: number;
    data_roam_charges?: any;
    recapture: boolean;
    mms_intl_roam_count: number;
    total_charges: number;
    plan_minutes: number;
    county_tax: number;
    kb_usage: number;
    peer_to_peer_charges: number;
    pro_roam_charges: number;
    personal_adjustment: number;
    per_minute_charge: number;
    additional_minutes: number;
    sms_intl_count: number;
    pda_description: NullableString;
    mms_intl_charges: number;
    download_charges: number;
    is_pooled: boolean;
  }
}
