import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#5C9ECD",
      contrastText: "#fff",
    },
    secondary: {
      main: "#3a516e",
    },
  },
  typography: {
    body1: {
      fontSize: 14,
    },
  },
});

export default theme;
