import { Alert, Typography } from "@mui/material";
import { fetchUserAuthenticated } from "@react-ms-apps/common/api";
import { fetchNavbarData } from "@react-ms-apps/common/api/navbar";
import { Container } from "@react-ms-apps/common/components";
import NavBar from "@react-ms-apps/common/components/NavBar";
import "@react-ms-apps/common/config/axios";
import { Loading } from "@react-ms-apps/common/types";
import { NavData } from "@react-ms-apps/common/types/nav";
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

// IMPORTANT: only use for dev environment
const passwordLink: NavData.NavDataItem = {
  href: "#",
  a_class: "",
  link_name: "Password",
  id: "settings",
  onclick: "return employeePopup();",
};

interface AppProps {
  useAsComponent?: boolean;
}

function App({ useAsComponent = false }: AppProps) {
  const [fetchingUserAuthenticated, setFetchingUserAuthenticated] =
    useState<Loading.LoadingState>(Loading.LoadingStates.Initial);
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [navData, setNavData] = useState<NavData.NavDataItem[]>([]);
  const [errorMessage, setErrorMessage] = useState("");

  const getNavbarData = async () => {
    try {
      const response = await fetchNavbarData();
      const navData = [...response];
      if (process.env.NODE_ENV === "development") {
        navData.push(passwordLink);
      }

      setNavData(navData);
    } catch (error) {
      setErrorMessage(
        "There was an error loading the navigation. Please try again later."
      );
      // send out error to error logging service
      Sentry.captureException(error);
    }
  };

  const getUserAuthenticated = async () => {
    if (fetchingUserAuthenticated !== Loading.LoadingStates.Initial) return;

    setFetchingUserAuthenticated(Loading.LoadingStates.Loading);
    try {
      // fetch user authenticated from API
      const userAuthenticated = await fetchUserAuthenticated();
      setUserAuthenticated(userAuthenticated);
      setFetchingUserAuthenticated(Loading.LoadingStates.Success);
    } catch (error) {
      setFetchingUserAuthenticated(Loading.LoadingStates.Error);
    }
  };

  useEffect(() => {
    getUserAuthenticated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // do not fetch nav data if user is not authenticated
    if (!userAuthenticated) return;

    // fetch nav data
    getNavbarData();
  }, [userAuthenticated]);

  if (
    fetchingUserAuthenticated === Loading.LoadingStates.Error ||
    !!errorMessage
  ) {
    return (
      <Container>
        <Alert severity="error">
          <Typography>
            There was an error loading the navigation. Please try again later.
          </Typography>
        </Alert>
      </Container>
    );
  }

  // wait for user authentication to complete
  if (fetchingUserAuthenticated !== Loading.LoadingStates.Success) return null;

  // do not override the default nav if no data available
  if (navData.length === 0) return null;

  return (
    <Routes>
      <Route
        path="*"
        element={<NavBar useAsComponent={useAsComponent} navData={navData} />}
      />
    </Routes>
  );
}

export default App;
