import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { LoadingButton } from "@mui/lab";
import { Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  ROUTES,
  establishZopeSession,
  fetchCustomLoginMessage,
  login,
} from "@react-ms-apps/common";
import Password from "@react-ms-apps/common/components/Password/Password";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import LoginWrapper from "../LoginWrapper";

interface SignInProps {
  allowForgotPassword: boolean;
}

export default function SignIn({ allowForgotPassword = true }: SignInProps) {
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [customLoginMessage, setCustomLoginMessage] = useState<string>("");

  const getCustomLoginMessage = async () => {
    try {
      const message = await fetchCustomLoginMessage();
      if (message) {
        setCustomLoginMessage(message);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const data = new FormData(event.currentTarget);
    const username = data.get("username") as string;
    const password = data.get("password") as string;

    if (!username || !password) return;

    setIsLoggingIn(true);

    try {
      await login(username, password);
    } catch (error) {
      // show error alert in UI
      if (error instanceof Error) {
        toast.error(
          "There was an error logging in. Try logging in again, resetting your password, or contacting support."
        );
      }
    } finally {
      setIsLoggingIn(false);
    }
  };

  const initZopeSession = async () => {
    await establishZopeSession();
  };

  useEffect(() => {
    // fetch custom login message
    getCustomLoginMessage();

    // establish zope session
    initZopeSession();
  }, []);

  return (
    <LoginWrapper loginMessage={customLoginMessage}>
      <Avatar className="m-1 mb-2" sx={{ bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in to your account
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        className="w-full mt-1"
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          placeholder="name@company.com"
          autoFocus
        />
        <Password
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          placeholder="*********"
        />
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          loading={isLoggingIn}
        >
          Sign In
        </LoadingButton>
        <Divider className="!border-dashed !mb-4 !mt-2" />

        {allowForgotPassword && (
          <div className="flex justify-end">
            <Link component={RouterLink} to={ROUTES.LOGIN.FORGOT_PASSWORD}>
              Forgot password?
            </Link>
          </div>
        )}
      </Box>
    </LoginWrapper>
  );
}
