import { Typography } from "@mui/material";
import {
  fetchOrgInfo,
  fetchSuppressMobilSenseLogo,
  fetchUserAuthenticated,
  fetchUserInfo,
} from "@react-ms-apps/common/api";
import Container from "@react-ms-apps/common/components/Container";
import { PartnerOrg } from "@react-ms-apps/common/types";
import { UserInfoDTO } from "@react-ms-apps/common/types/user";
import { getClientDB } from "@react-ms-apps/common/utils";
import Logout from "@react-ms-apps/common/utils/logout";
import Navigation from "@react-ms-apps/navigation/src/App";
import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useState } from "react";

interface HeaderProps {
  showNavigation?: boolean;
}

export const Header = ({ showNavigation = true }: HeaderProps) => {
  const [suppressMobilSenseLogo, setSuppressMobilSenseLogo] = useState(true);
  const [orgInfo, setOrgInfo] = useState<PartnerOrg | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfoDTO | null>(null);

  const fetchAssets = async () => {
    try {
      const suppressLogo = await fetchSuppressMobilSenseLogo();
      setSuppressMobilSenseLogo(suppressLogo);

      const orgInfo = await fetchOrgInfo();
      setOrgInfo(orgInfo);

      const userInfo = await fetchUserInfo();
      setUserInfo(userInfo);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const fetchIsAuthed = useCallback(async () => {
    try {
      const authed = await fetchUserAuthenticated();
      setIsAuthenticated(authed);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  useEffect(() => {
    fetchAssets();
    fetchIsAuthed();
  }, [fetchIsAuthed]);

  const getPartnerLogo = () => {
    if (!orgInfo) return null;

    if (orgInfo.logo_url) {
      return (
        <div className="overflow-hidden -ml-6">
          <img
            className="absolute -mt-2"
            src={orgInfo.logo_url}
            alt="Partner Logo"
          />
        </div>
      );
    }

    if (orgInfo.name) {
      return <Typography variant="h5">{orgInfo.name}</Typography>;
    }

    return null;
  };

  const getUserName = () => {
    if (!userInfo) return "";

    return `${userInfo.first_name} ${userInfo.last_name}`;
  };

  const showNavigationElements = showNavigation && isAuthenticated;

  return (
    <header className="flex flex-col" id="header">
      <nav className="shadow-md">
        <Container className="!flex flex-wrap justify-between items-center py-4 pt-2">
          <div className="relative min-h-[65px] min-w-[320px] flex flex-1 items-center">
            {getPartnerLogo()}
          </div>
          <div className="flex flex-1 items-center justify-center">
            <img
              id="image_title"
              alt="Mobile Expense Management"
              src={`/${getClientDB()}/CSS/v1/images/StandardTitle.gif`}
              className="h-14 mt-2"
            />
          </div>
          <div className="relative min-h-[65px] min-w-[320px] overflow-hidden flex flex-1 justify-end">
            {!suppressMobilSenseLogo && (
              <img
                data-testid="mobil-sense-logo"
                alt="Header Logo"
                className="absolute -top-4 -mr-[30px]"
                src="/static/app/img/MS15-MobilSense-Logo.png"
                height={108}
                width={300}
              />
            )}
          </div>
        </Container>

        <Container className="pb-2 -my-2 !flex justify-between items-center">
          <div className="flex flex-1">
            {showNavigationElements && <Navigation useAsComponent />}
          </div>

          <div className="flex flex-1 justify-end">
            {showNavigationElements && (
              <div className="flex gap-3 flex-shrink-0">
                <span className="text-sm text-gray-500">{getUserName()}</span>
                <span
                  onClick={Logout}
                  className="text-sm text-primary-600 font-medium cursor-pointer"
                >
                  Logout
                </span>
              </div>
            )}
          </div>
        </Container>
      </nav>
    </header>
  );
};

export default Header;
